import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Button, BUTTON_VARIANTS, Modal, PaypalButton, SecureInfo } from 'src/components';
import { trial_bg } from 'src/survey-data/images';
import creditCardIconSvg from 'src/assets/images/trial/credit-card-icon.svg';

import { ROUTES } from '../../../constants/routes';
import { useAnalytic } from '../../../contexts';
import { getLSValue } from '../../../utils/locale-storage';
import { USER_DATA } from '../../../constants/common';
import { UserLocalStorageData } from '../../../types/user';
import { ConfigResponse, webApiService } from '../../../services/web-api';
import { getCurrency } from '../../../utils/currency';
import { PaymentModal } from './payment-modal';
import { Info } from './info';

import styles from './variant-b.module.scss';

type VariantBProps = {
  purchaseConfirm: (purchaseId: string) => void;
  paymentConfig?: ConfigResponse;
};

const VariantB = ({ purchaseConfirm, paymentConfig }: VariantBProps) => {
  const currentUserData = useMemo(() => getLSValue(USER_DATA, true) as UserLocalStorageData, []);
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [paypalPurchaseId, setPaypalPurchaseId] = useState('');

  useEffect(() => {
    if (!currentUserData?.user_id || !paymentConfig?.checkout || paypalPurchaseId) {
      return;
    }

    (async () => {
      try {
        const data = await webApiService.purchaseSubscriptions({
          app: process.env.REACT_APP_NAME_APP || '',
          billing_id: paymentConfig?.checkout.paypal.account_id || '',
          billing_type: 'paypal',
          customer_id: currentUserData.user_id,
          paypal: {
            app: process.env.REACT_APP_NAME_APP || '',
            customer_id: currentUserData.user_id,
            plan: currentUserData.plan?.introPlanKey || '',
          },
          stripe: {
            customer_id: currentUserData.user_id,
            intro_plan_key: currentUserData.plan?.introPlanKey || '',
            main_plan_key: currentUserData.plan?.mainPlanKey || '',
            trial_duration: currentUserData.plan?.trialDuration || 0,
          },
        });

        if (data) {
          setPaypalPurchaseId(data.purchase_id);
        }
      } catch (error: unknown) {
        console.error(error);
      }
    })();
  }, [currentUserData.plan, currentUserData.user_id, paymentConfig?.checkout, paypalPurchaseId]);

  const { sendEvent } = useAnalytic();

  const handleClick = (event: FormEvent) => {
    event.preventDefault();
    sendEvent('PerkyOnboardingTrialClicked', currentUserData.plan);

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePaypalClick = () => {
    sendEvent('PerkyOnboardingPaywallPaymentAttempt', {
      paymentMethod: 'paypal',
      ...currentUserData,
    });
  };

  const handlePaypalSuccess = async () => {
    sendEvent('PerkyOnboardingPaywallSubscriptionPurchased', {
      status: 'success',
      paymentMethod: 'paypal',
      ...currentUserData,
    });

    purchaseConfirm(paypalPurchaseId);
    navigate({ pathname: ROUTES.SUCCESS_PAGE, search: location.search });
  };

  const handlePaypalFailed = (error: unknown) => {
    console.error(error);
    sendEvent('PerkyOnboardingPaywallSubscriptionPurchased', {
      status: 'error',
      paymentMethod: 'paypal',
      ...currentUserData,
    });

    navigate({ pathname: ROUTES.FAILED_PAGE, search: location.search });
  };

  return (
    <div className={styles.root}>
      <div className={styles.bg}>
        <img src={trial_bg} alt="trial background" />
      </div>
      <div className={styles.container}>
        <h1 className={styles.title}>
          <span className={styles.titleBold}>Start your 7-day trial</span>
        </h1>
        <div className={styles.subTitle}>
          Create an <span className={styles.bold}>unlimited number</span> of images at high speed and with the best
          quality
        </div>
        <div className={styles.noCommitment}>No commitment. Cancel anytime.</div>
        <div className={styles.total}>
          <div className={styles.totalTitle}>Total today</div>{' '}
          {getCurrency(currentUserData.plan?.trialPrice, currentUserData.plan?.currency)}
        </div>
        <div className={styles.cost}>
          <div className={styles.costTitle}>
            Your cost per week after trial
            <br />
            <span className={styles.opacityText}>Save $10 every week</span>
          </div>
          <div className={styles.costAmount}>
            {getCurrency(currentUserData.plan?.price, currentUserData.plan?.currency)} <br />
            <span className={clsx(styles.opacityText, styles.crossedText)}>14,99$</span>
          </div>
        </div>

        {paymentConfig && paypalPurchaseId && (
          <PaypalButton
            clickCallback={handlePaypalClick}
            userId={currentUserData.user_id}
            purchaseId={paypalPurchaseId}
            paypalPlanKey={currentUserData.plan?.paypalPlanKey || ''}
            successCallback={handlePaypalSuccess}
            errorCallback={handlePaypalFailed}
            clientId={paymentConfig.checkout.paypal.client_id}
          />
        )}

        <Button variant={BUTTON_VARIANTS.SECONDARY} className={styles.submitButton} onClick={handleClick}>
          <img className={styles.creditCard} src={creditCardIconSvg} alt="credit card icon" /> Credit / Debit Card
        </Button>

        <Info />
        <SecureInfo />
      </div>

      {showModal ? (
        <Modal overlayClassName={styles.overviewModal}>
          {paymentConfig && (
            <PaymentModal
              onClose={handleCloseModal}
              billingId={paymentConfig.checkout.stripe.account_id}
              purchaseConfirm={purchaseConfirm}
              stripePublishableKey={paymentConfig.checkout.stripe.publishable_key}
            />
          )}
        </Modal>
      ) : null}
    </div>
  );
};

export { VariantB };
