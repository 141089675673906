import { FC, ReactNode, useLayoutEffect } from 'react';
import amplitude from 'amplitude-js';

import { getUserAgentInfo, OS } from '../utils/userAgentInfo';
import { getLSValue } from '../utils/locale-storage';
import { BOT_REGEXP, USER_DATA } from '../constants/common';
import { UserLocalStorageData } from '../types/user';
import useQueryParams from '../hooks/use-query-params';
import { useAnalytic } from '../contexts';

type Props = {
  children: ReactNode;
};

const BotDetectionMiddleware: FC<Props> = ({ children }) => {
  const { getQueryParams } = useQueryParams();
  const { amplitudeInit } = useAnalytic();

  useLayoutEffect(() => {
    const userAgentInfo = getUserAgentInfo();
    const isBot = BOT_REGEXP.test(navigator.userAgent);
    const currentParams = getQueryParams();

    const isFunnelRedirect =
      amplitudeInit &&
      (userAgentInfo.os === OS.IosIphone || userAgentInfo.os === OS.Android) &&
      !isBot &&
      currentParams?.utm_source &&
      process.env.REACT_APP_BOT_REDIRECT_URL;

    if (!isFunnelRedirect) {
      return;
    }

    const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;

    const validParams = Object.fromEntries(
      Object.entries(currentParams)
        .filter(([, value]) => value !== null)
        .map(([key, value]) => [key, String(value)]),
    );

    if (userLSData?.user_id) {
      validParams.customer_id = userLSData.user_id;
    }

    validParams.ampDeviceId = amplitude.getInstance().getDeviceId();
    validParams.ampSessionId = amplitude.getInstance().getSessionId().toString();

    const searchParams = new URLSearchParams(validParams).toString();

    const redirectUrl = `${process.env.REACT_APP_BOT_REDIRECT_URL}?${searchParams}`;

    window.location.replace(redirectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amplitudeInit]);

  if (!amplitudeInit) {
    return null;
  }

  return <>{children}</>;
};

export { BotDetectionMiddleware };
