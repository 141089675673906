import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import amplitude from 'amplitude-js';

import { AnalyticContext } from './analytic-context';
import { logEvent } from './utils';

type AnalyticProviderProps = {
  children: ReactNode;
};

type AmplitudeEvent = { name: string; props?: Record<string, any> };

type AmplitudeUserProperties = Record<string, any>;

const AnalyticProvider = ({ children }: AnalyticProviderProps) => {
  const [isAmplitudeInit, setIsAmplitudeInit] = useState(false);

  const analyticsInit = useRef(false);
  const eventsQueue = useRef<AmplitudeEvent[]>([]);
  const userPropertiesQueue = useRef<AmplitudeUserProperties | null>(null);

  useEffect(() => {
    if (analyticsInit.current) {
      return;
    }

    analyticsInit.current = true;

    amplitude.getInstance().init(
      process.env.REACT_APP_AMPLITUDE_API_KEY || '',
      undefined,
      {
        onError: () => {
          setIsAmplitudeInit(true);
        },
      },
      (instance) => {
        instance.logEvent('quiz_load', {
          domain: window.location.hostname,
          previous_domain: document.referrer,
          query_string: window.location.search,
        });
        setIsAmplitudeInit(true);
      },
    );
  }, []);

  const sendEvent = useCallback((name: string, data?: Record<string, string> | string) => {
    let updatedData;

    if (data) {
      updatedData = { value: data };
    }

    const params = Object.assign(
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_category: '<WebOnboarding>',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_funnel_version: '<v1>',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_project: '<Perky>',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_release_version: process.env.REACT_APP_RELEASE_VERSION,
      },
      updatedData,
    );

    if (process.env.NODE_ENV !== 'production') {
      logEvent(name, params);
    }

    try {
      window.gtag('event', name, params);
    } catch (error: unknown) {
      console.error(`Failed to sent gtag event ${name}`, error);
    }
  }, []);

  const sendAmplitudeEvent = useCallback(
    (event: AmplitudeEvent) => {
      const { name, props } = event;

      if (!isAmplitudeInit) {
        eventsQueue.current.push(event);
        return;
      }

      amplitude.getInstance().logEvent(name, props);
    },
    [isAmplitudeInit],
  );

  const setUserProperties = useCallback(
    (userProperties: AmplitudeUserProperties) => {
      if (!isAmplitudeInit) {
        userPropertiesQueue.current = { ...userPropertiesQueue.current, ...userProperties };
        return;
      }
      amplitude.getInstance().setUserProperties(userProperties);
    },
    [isAmplitudeInit],
  );

  useEffect(() => {
    if (!isAmplitudeInit) {
      return;
    }

    if (userPropertiesQueue.current) {
      setUserProperties(userPropertiesQueue.current);
    }

    eventsQueue.current.forEach((event) => {
      sendAmplitudeEvent(event);
    });
  }, [isAmplitudeInit, sendAmplitudeEvent, setUserProperties]);

  const analyticsValue = useMemo(
    () => ({ sendEvent, sendAmplitudeEvent, setUserProperties, amplitudeInit: isAmplitudeInit }),
    [isAmplitudeInit, sendAmplitudeEvent, sendEvent, setUserProperties],
  );

  return <AnalyticContext.Provider value={analyticsValue}>{children}</AnalyticContext.Provider>;
};

export { type AnalyticProviderProps, type AmplitudeEvent, type AmplitudeUserProperties, AnalyticProvider };
