/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading, PaypalButton, Title } from 'src/components';
import clsx from 'clsx';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { ConfigResponse, webApiService } from '../../../services/web-api';
import { getLSValue } from '../../../utils/locale-storage';
import { USER_DATA } from '../../../constants/common';
import { useAnalytic } from '../../../contexts';
import { UserLocalStorageData } from '../../../types/user';
// eslint-disable-next-line import/order
import { ROUTES } from '../../../constants/routes';
// eslint-disable-next-line import/order
import { StripeForm } from './stripe-form';
import { getCurrency } from '../../../utils/currency';

import styles from './variant-a.module.scss';

type VariantAProps = {
  paymentConfig?: ConfigResponse;
  purchaseConfirm: (purchaseId: string) => void;
};

const VariantA = ({ purchaseConfirm, paymentConfig }: VariantAProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserData = useMemo(() => getLSValue(USER_DATA, true) as UserLocalStorageData, []);
  const { sendEvent } = useAnalytic();
  const [loading, setLoading] = useState(true);
  const [paypalPurchaseId, setPaypalPurchaseId] = useState('');

  useEffect(() => {
    if (!currentUserData?.user_id || !paymentConfig?.checkout || paypalPurchaseId) {
      return;
    }

    (async () => {
      try {
        const data = await webApiService.purchaseSubscriptions({
          app: process.env.REACT_APP_NAME_APP || '',
          billing_id: paymentConfig?.checkout.paypal.account_id || '',
          billing_type: 'paypal',
          customer_id: currentUserData.user_id,
          paypal: {
            app: process.env.REACT_APP_NAME_APP || '',
            customer_id: currentUserData.user_id,
            plan: currentUserData.plan?.introPlanKey || '',
          },
          stripe: {
            customer_id: currentUserData.user_id,
            intro_plan_key: currentUserData.plan?.introPlanKey || '',
            main_plan_key: currentUserData.plan?.mainPlanKey || '',
            trial_duration: currentUserData.plan?.trialDuration || 0,
          },
        });

        if (data) {
          setPaypalPurchaseId(data.purchase_id);
        }
      } catch (error: unknown) {
        console.error(error);
      }
    })();
  }, [currentUserData.plan, currentUserData.user_id, paymentConfig?.checkout, paypalPurchaseId]);

  const handlePaypalClick = () => {
    sendEvent('PerkyOnboardingPaywallPaymentAttempt', {
      paymentMethod: 'paypal',
      ...currentUserData,
    });
  };

  const handlePaypalSuccess = async () => {
    sendEvent('PerkyOnboardingPaywallSubscriptionPurchased', {
      status: 'success',
      paymentMethod: 'paypal',
      ...currentUserData,
    });

    purchaseConfirm(paypalPurchaseId);

    navigate({ pathname: ROUTES.SUCCESS_PAGE, search: location.search });
  };

  const handlePaypalFailed = (error: unknown) => {
    console.error(error);
    sendEvent('PerkyOnboardingPaywallSubscriptionPurchased', {
      status: 'error',
      paymentMethod: 'paypal',
      ...currentUserData,
    });

    navigate({ pathname: ROUTES.FAILED_PAGE, search: location.search });
  };

  return (
    <div className={styles.root}>
      <Title className={styles.title} title="Select a secure" secondPartTitle="payment method" isSmall />

      {paymentConfig && paypalPurchaseId && (
        <PaypalButton
          clickCallback={handlePaypalClick}
          paypalPlanKey={currentUserData.plan?.paypalPlanKey || ''}
          successCallback={handlePaypalSuccess}
          errorCallback={handlePaypalFailed}
          userId={currentUserData.user_id}
          purchaseId={paypalPurchaseId}
          clientId={paymentConfig.checkout.paypal.client_id}
        />
      )}
      {loading && (
        <div className={styles.loading}>
          <Loading isDark />
        </div>
      )}
      {paymentConfig && (
        <div className={clsx({ [styles.hidden]: false })}>
          <Elements
            stripe={loadStripe(paymentConfig.checkout.stripe.publishable_key)}
            options={{
              mode: 'subscription',
              amount: Number(currentUserData?.plan?.trialPrice || currentUserData?.plan?.price),
              currency: currentUserData.plan?.currency?.toLowerCase() || 'usd',
            }}
          >
            <StripeForm
              setLoading={setLoading}
              total={getCurrency(currentUserData?.plan?.trialPrice, currentUserData?.plan?.currency)}
              purchaseConfirm={purchaseConfirm}
              billingId={paymentConfig.checkout.stripe.account_id}
            />
          </Elements>
        </div>
      )}
    </div>
  );
};

export { VariantA };
